import type { ComponentType } from "react"

export const withReloadPage = (Component): ComponentType => {
    return (props) => {
        const handleClick = () => {
            window.location.href = window.location.href
        }
        return <Component {...props} onClick={handleClick} />
    }
}
